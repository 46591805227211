/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location }) => {
  // Get the ID from the URL hash
  const id = location.hash.replace("#", "");

  // Scroll to the element with the corresponding ID
  if (id) {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 5);
  }
};
