exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-collections-notice-tsx": () => import("./../../../src/pages/collections-notice.tsx" /* webpackChunkName: "component---src-pages-collections-notice-tsx" */),
  "component---src-pages-drivers-champion-tsx": () => import("./../../../src/pages/drivers-champion.tsx" /* webpackChunkName: "component---src-pages-drivers-champion-tsx" */),
  "component---src-pages-fan-type-image-tsx": () => import("./../../../src/pages/fan-type/image.tsx" /* webpackChunkName: "component---src-pages-fan-type-image-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-og-legend-tsx": () => import("./../../../src/pages/og-legend.tsx" /* webpackChunkName: "component---src-pages-og-legend-tsx" */),
  "component---src-pages-pitlane-socialite-tsx": () => import("./../../../src/pages/pitlane-socialite.tsx" /* webpackChunkName: "component---src-pages-pitlane-socialite-tsx" */),
  "component---src-pages-quiz-tsx": () => import("./../../../src/pages/quiz.tsx" /* webpackChunkName: "component---src-pages-quiz-tsx" */),
  "component---src-pages-tech-whiz-tsx": () => import("./../../../src/pages/tech-whiz.tsx" /* webpackChunkName: "component---src-pages-tech-whiz-tsx" */)
}

